import React, { useEffect, useState, createRef } from 'react';
import Notification from './Notification';

const Notifications = () => {
  const $notificationToggle = $('.js-header-notification-toggle');
  const $notificationWrapper = $('.js-header-notification-wrapper');

  const notificationsRef = createRef();
  const [open, setOpen] = useState(false);
  const [hasOpenedNotifications, setHasOpenedNotifications] = useState(false);
  const [showMarkAllSeenButton, setShowMarkAllSeenButton] = useState(false);
  const [notificationData, setNotificationData] = useState({
    notification_list: [],
    last_opened_at: 0,
    fetched_at: 0,
    new_notifications: false
  });

  // API requests
  const fetchNotifications = () => {
    fetch('/notifications')
      .then((res) => res.json())
      .then((res) => setNotificationData(res));
  };

  const postNotificationsOpen = () => {
    fetch('/notifications/open', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ fetched_at: notificationData.fetched_at })
    });
  };

  // Functions
  const setupButtonListener = () => {
    const notificationList = $(notificationsRef.current);
    $notificationToggle.on('click', () => {
      setHasOpenedNotifications(true);
      notificationList.slideToggle('fast');

      const expanded = $notificationToggle.attr('aria-expanded') === 'true';
      $notificationToggle.attr('aria-expanded', String(!expanded));
      $notificationToggle.attr('aria-label', expanded ? 'Open notifications' : 'Close notifications');
      setOpen((prevOpen) => !prevOpen);

      $('body').on('click', (e) => {
        if (!$notificationWrapper[0].contains(e.target)) {
          notificationList.slideUp('fast');
        }
      });
    });
  };

  const markAsSeen = () => {
    const updatedNotificationData = { ...notificationData };
    updatedNotificationData.notification_list.forEach((notification) => {
      // eslint-disable-next-line no-param-reassign
      notification.seen = true;
    });
    setNotificationData(updatedNotificationData);
  };

  const displayRedDot = () => {
    const {
      notification_list: notificationList,
      last_opened_at: lastOpenedAt
    } = notificationData;

    let newNotificationCount = 0;
    notificationList.forEach((notification) => {
      if (notification.updated_at > lastOpenedAt && !notification.seen) {
        newNotificationCount += 1;
      }
    });

    if (newNotificationCount === 0) return;

    // Keep numbers small
    if (newNotificationCount > 9) {
      newNotificationCount = '9+';
    }

    $notificationToggle.addClass('Notifications-button--new');
    $('.js-notifications-dot').text(newNotificationCount);
  };

  const handleMarkAllSeenClick = () => {
    markAsSeen();
    fetch('/notifications/mark_all_as_seen', {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/json'
      }
    });
  };

  // Hooks
  useEffect(() => {
    fetchNotifications();
    setupButtonListener();
  }, []);

  useEffect(() => {
    if (notificationData.new_notifications === true) {
      displayRedDot();
    }
  }, [notificationData]);

  useEffect(() => {
    if (hasOpenedNotifications) {
      $notificationToggle.removeClass('Notifications-button--new');
      postNotificationsOpen();
    }
  }, [hasOpenedNotifications]);

  useEffect(() => {
    const hasUnseenNotifications = notificationData.notification_list.some((notification) => !notification.seen);

    setShowMarkAllSeenButton(hasUnseenNotifications);
  }, [notificationData]);

  const {
    notification_list: notificationList,
    last_opened_at: lastOpenedAt,
    fetched_at: fetchedAt
  } = notificationData;

  return (
    <ul ref={notificationsRef} className="Notifications HeaderAction-dropdown" aria-hidden={!open}>
      {notificationList.length ? (
        <>
          {notificationList.map((notification) => (
            <Notification
              notificationItem={notification}
              lastOpenedAt={lastOpenedAt}
              fetchedAt={fetchedAt}
              key={notification.updated_at}
            />
          ))}
          {true && (
            <li className="Notification Notification--link Notification--mark-all">
              <button
                type="button"
                className="remove-button-style w-100 color-black"
                onClick={handleMarkAllSeenClick}
              >
                Mark all as seen
              </button>
            </li>
          )}
        </>
      ) : (
        <li className="Notification">
          <span className="fw6 pv2">You have no notifications.</span>
        </li>
      )}
    </ul>
  );
};

export default Notifications;
