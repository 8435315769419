import React from 'react';
import ReactDOM from 'react-dom';
import Notifications from './components/Notifications';

const renderNotifications = (element) => {
  ReactDOM.render(
    <Notifications />,
    element
  );
};

$('html').on('consumer.dependencies.notifications.loaded', () => {
  const notificationElement = document.getElementById('notifications');
  if (notificationElement) renderNotifications(notificationElement);
});
