import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Notification = ({ notificationItem, fetchedAt }) => {
  const {
    seen, path, copy, updated_at: updatedAt
  } = notificationItem;
  const [isSeen, setIsSeen] = useState(seen);
  const timeInMinutes = (fetchedAt - updatedAt) / 60;
  let timeAgo = 'Now';

  // 2+ days
  if (timeInMinutes > (2 * 24 * 60)) {
    timeAgo = `${Math.floor(timeInMinutes / 60 / 24)} days ago`;
  } else if (timeInMinutes > (24 * 60)) {
    timeAgo = '1 day ago';
  } else if (timeInMinutes > (2 * 60)) {
    timeAgo = `${Math.floor(timeInMinutes / 60)} hours ago`;
  } else if (timeInMinutes > 60) {
    timeAgo = '1 hour ago';
  } else if (timeInMinutes > 2) {
    timeAgo = `${Math.floor(timeInMinutes)} minutes ago`;
  } else if (timeInMinutes > 1) {
    timeAgo = '1 minute ago';
  }

  const dateTitle = new Date(updatedAt * 1000).toLocaleString();

  const handleNotificationClick = () => {
    if (isSeen) {
      return;
    }

    setIsSeen(true);
    const notificationId = notificationItem.id;

    fetch(`/notifications/${notificationId}/mark_as_seen`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/json'
      }
    });
  };

  useEffect(() => {
    if (seen) {
      setIsSeen(seen);
    }
  }, [seen]);

  return (
    <li className="Notification Notification--link">
      <a href={path} onClick={handleNotificationClick} className="not-a">
        <div>
          <div dangerouslySetInnerHTML={{ __html: copy }} />
          <div className="Notification-time" title={dateTitle}>
            {timeAgo}
          </div>
        </div>
        {!isSeen && <div className="Notification-dot" />}
      </a>
    </li>
  );
};

Notification.propTypes = {
  notificationItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    seen: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    copy: PropTypes.string.isRequired,
    updated_at: PropTypes.number.isRequired
  }).isRequired,
  fetchedAt: PropTypes.number.isRequired
};

export default Notification;
